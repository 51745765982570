import axios from 'axios'

import { ROOT_STATE_MACHINE_BASE_URL } from '../../config/env'
import { THEMES_SNAKE_TRANSLATION } from '../../config/consts'

import { getIdToken, toCamelCase } from '../../utils'

export const startRootStepFunction = async ({
  document,
  queueUrl,
  enableSerasa,
  enablePartners,
  spreadType,
  username,
  themes,
  options
}) => {
  const url = `${ROOT_STATE_MACHINE_BASE_URL}/boanota/search`
  // const url = SCOPE === 'boanota' ? `${ROOT_STATE_MACHINE_BASE_URL}/boanota_aberto/search` : `${ROOT_STATE_MACHINE_BASE_URL}/boanota/search`

  const idToken = await getIdToken()

  if (!username) {
    throw new Error('Username is required')
  }

  // basicInfo sempre é necessário, uma vez que todos os outros temas dependem dele
  const translatedThemes = Array.from(
    new Set(
      [...themes, 'basicInfo']
        .map(theme => THEMES_SNAKE_TRANSLATION[theme])
        .filter(Boolean)
    )
  )

  const { data } = await axios.post(
    url,
    {
      document,
      ...options,
      spread_type: spreadType,
      skip_serasa: !enableSerasa,
      skip_partners: !enablePartners,
      themes: translatedThemes,
      queue_url: queueUrl
    },
    {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  )

  return toCamelCase(data)
}

export const updateDocumentStatus = async ({ executionId, status }) => {
  const url = `${ROOT_STATE_MACHINE_BASE_URL}/boanota/update-doc-status`

  const idToken = await getIdToken()

  const { data } = await axios.post(
    url,
    {
      execution_id: executionId,
      status
    },
    {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  )

  return toCamelCase(data)
}
