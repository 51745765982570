import axios from 'axios'

import { ROOT_STATE_MACHINE_BASE_URL } from '../../config/env'

import { toCamelCase } from '../../utils'

import { getIdToken, getUserData } from '../aws'

export const getCredits = async () => {
  const url = `${ROOT_STATE_MACHINE_BASE_URL}/boanota/get_user`

  const [idToken, user] = await Promise.all([getIdToken(), getUserData()])

  const { data } = await axios.get(url, {
    params: {
      username: user.username
    },
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  })

  const formattedData = toCamelCase(data)
  return formattedData.credit
}

export const updateCredits = async ({ credits }) => {
  const url = `${ROOT_STATE_MACHINE_BASE_URL}/boanota/change_credit`

  const [idToken, user] = await Promise.all([getIdToken(), getUserData()])

  const { data } = await axios.post(
    url,
    {
      username: user.username,
      credit: credits
    },
    {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  )

  const formattedData = toCamelCase(data)
  console.log('formattedData', formattedData)
  return formattedData.message.credit
}

export const getThemeCosts = async ({ document, themes, skipRelateds }) => {
  const url = `${ROOT_STATE_MACHINE_BASE_URL}/boanota/theme_cost`

  const [idToken, user] = await Promise.all([getIdToken(), getUserData()])

  const { data } = await axios.post(
    url,
    {
      username: user.username,
      document,
      themes,
      skip_relateds: skipRelateds
    },
    {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  )

  const formattedData = toCamelCase(data)
  console.log('formattedData', formattedData)
  return formattedData.message.credit
}
