export const SEARCH_OPTIONS = [
  {
    value: 'all',
    label: 'Todos',
    flags: []
  },
  // todas as steps dependem dos dados básicos, por isso ela sempre estará habilitada
  {
    value: 'basicInfo',
    label: 'Dados Básicos',
    flags: [],
    fixed: true
  },
  {
    value: 'grupoSocietario',
    label: 'Grupo Societário',
    flags: ['skip_cruzamentos']
  },
  {
    value: 'patrimonio',
    label: 'Patrimônio',
    flags: [
      'skip_aeronaves',
      'skip_sncr',
      'skip_inpi',
      'skip_ibama',
      'skip_sigef'
    ]
  },
  {
    value: 'pontosDeAtencao',
    label: 'Pontos de Atenção',
    flags: [
      'skip_termo_suspensao',
      'skip_termo_embargo',
      'skip_termo_apreensao',
      'skip_auto_infracao',
      'skip_pep',
      'skip_acordos_leniencia',
      'skip_ceaf',
      'skip_ceis',
      'skip_cepim',
      'skip_cnep',
      'skip_cadastro_expulsoes',
      'skip_ofac',
      'skip_trabalho_escravo'
    ]
  },
  {
    value: 'offshore',
    label: 'Offshore',
    flags: ['skip_offshore', 'skip_registrosrde']
  },
  {
    value: 'endividamento',
    label: 'Endividamento',
    flags: [
      'skip_bndes',
      'skip_cheque',
      'skip_cndt',
      'skip_comprot',
      'skip_pgfn',
      'skip_protesto'
    ]
  },
  {
    value: 'juridico',
    label: 'Jurídico',
    flags: ['skip_bigboost_juridico']
  },
  {
    value: 'midiaDesabonadora',
    label: 'Mídia Desabonadora',
    flags: ['skip_news']
  },
  {
    value: 'operacionais',
    label: 'Operacionais',
    flags: ['skip_operacionais']
  }
]

export const THEMES_SNAKE_TRANSLATION = {
  basicInfo: 'basic_info',
  grupoSocietario: 'grupo_societario',
  patrimonio: 'patrimonio',
  pontosDeAtencao: 'pontos_de_atencao',
  offshore: 'offshore',
  endividamento: 'endividamento',
  juridico: 'juridico',
  midiaDesabonadora: 'midia_desabonadora',
  operacionais: 'operacionais'
}
