import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { toast } from 'react-toastify'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

import { selectMinData } from '../../../../redux/selectors/dadosBasicos'
import { selectRelateds } from '../../../../redux/selectors/relateds'
import { selectSearchOptions } from '../../../../redux/selectors/searchOptions'

import { fetchDiscreditingMediaFromS3 } from '../../../../lib/s3Theme'
import mapWithRelationship from '../../../../lib/detectRelationship'

import {
  formatDocument,
  hashObject,
  factoryDate,
  formatDate,
  onlyNumbers,
  isPending
} from '../../../../utils'

import { Content, Loading, CustomWindow } from '../../../../components'
import Table from '../../../../components/Table'
import { onSortDate, onSortDocument } from '../../../../components/Table/sort'

const { SearchBar } = Search

export default function MidiaDesabonadora () {
  const discreditingMediaTheme = useSelector(
    state => state.discreditingMediaTheme
  )
  const { pages: enabledPages } = useSelector(selectSearchOptions)
  const { targetName, targetDocument } = useSelector(selectMinData)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    discreditingNews: { data: [], loaded: false, error: false }
  })

  useEffect(() => {
    if (isPending(discreditingMediaTheme.discreditingNews)) {
      return
    }

    setLoading(true)
    fetchDiscreditingMediaFromS3({
      newsPayload: discreditingMediaTheme.discreditingNews
    })
      .then(media => {
        setData(media)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          discreditingNews: { data: [], loaded: false, error: true }
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [discreditingMediaTheme])

  const isStepPending =
    targetDocument && isPending(discreditingMediaTheme.discreditingNews)

  return (
    <>
      <ReactTooltip />
      <Content
        title={`Mídia Desabonadora ${targetName ? `- ${targetName}` : ''}`}
        subtitle='Busca na mídia por notícias desabonadoras.'
      >
        {!targetDocument ? null : !enabledPages.midiaDesabonadora ? (
          <div className='d-flex w-100 h-100 flex-1 justify-content-center align-items-center'>
            <p className='h3'>Esta aba está desabilitada!</p>
          </div>
        ) : loading || isStepPending ? (
          <Loading />
        ) : (
          <>
            <DiscreditingNews discreditingNews={data.discreditingNews} />
          </>
        )}
      </Content>
    </>
  )
}

const DiscreditingNews = React.memo(({ discreditingNews }) => {
  const name = 'discreditingMedia.news'
  const title = 'Notícias'
  const subtitle = 'Dados indexados pelo Google News e selecionados com NLP'

  const { isCPF, targetDocument } = useSelector(selectMinData)
  const relateds = useSelector(selectRelateds)

  if (!discreditingNews.loaded && !discreditingNews.error) {
    return <div />
  }

  if (discreditingNews.error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <CustomWindow
          title={title}
          subtitle={`${
            targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
          } ${formatDocument(targetDocument)}`}
          name={name}
          showNote
        >
          <div>
            Ops! Aconteceu um erro e não foi possível carregar as notícias
            desabonadoras.
          </div>
        </CustomWindow>
      </div>
    )
  }

  const columns = [
    {
      dataField: 'documento',
      text: 'CPF / CNPJ',
      sort: true,
      sortFunc: onSortDocument
    },
    { dataField: 'nomePessoa', text: 'Nome', sort: true },
    {
      dataField: 'grau',
      text: 'Grau',
      sort: true
    },
    {
      dataField: 'title',
      text: 'Título',
      sort: true
    },
    {
      dataField: 'fonte',
      text: 'Fonte',
      sort: true
    },
    {
      dataField: 'dataPublicacao',
      text: 'Data Publicação',
      sort: true,
      sortFunc: onSortDate
    }
  ]

  const tableData = (discreditingNews.data || []).flatMap(item => {
    const document = item.document
    const name = item.name
    const items = item.data
    return items.map(item_ => ({ document, name, ...item_ }))
  })

  return (
    <ToolkitProvider
      keyField='id'
      columns={columns.map(item => ({
        ...item,
        align: 'center',
        headerAlign: 'center'
      }))}
      data={mapWithRelationship({
        array: tableData,
        documentKey: 'document',
        nameKey: 'name',
        relateds
      }).map((item, key) => {
        const id = hashObject({ item, key })
        return {
          id,
          documento: item.document ? (
            <a
              href={`?documento=${onlyNumbers(item.document)}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {formatDocument(item.document)}
            </a>
          ) : (
            '-'
          ),
          grau: item.relationship,
          nomePessoa: item.name,
          title: item.title,
          fonte: item.site ? (
            <a href={item.link} target='_blank' rel='noopener noreferrer'>
              {item.site}
            </a>
          ) : (
            '-'
          ),
          dataPublicacao: item.datetime
            ? formatDate(factoryDate(item.datetime))
            : '-'
        }
      })}
      search
    >
      {props => (
        <CustomWindow title={title} name={name} subtitle={subtitle} showNote>
          <div className='col-12 text-lg-right'>
            <SearchBar {...props.searchProps} placeholder='Pesquisar' />
          </div>
          <Table enablePagination {...props.baseProps} />
        </CustomWindow>
      )}
    </ToolkitProvider>
  )
})
