import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { useSelector } from 'react-redux'

import { selectMinData } from '../../redux/selectors/dadosBasicos'
import { selectSearchOptions } from '../../redux/selectors/searchOptions'

import { EXCLUSIVE_CONTENTS, StaticScopeMetadata } from '../../lib/scope'

import iconDadosBasicos from '../../assets/img/icons/sidebar/dados_basicos.svg'
import iconJuridico from '../../assets/img/icons/sidebar/juridico.svg'
import iconGrupoSocietario from '../../assets/img/icons/sidebar/grupo_societario.svg'
import iconPatrimonio from '../../assets/img/icons/sidebar/patrimonio.svg'
import iconBandeiraAmarela from '../../assets/img/icons/sidebar/bandeira_amarela.svg'
import iconFormulario from '../../assets/img/icons/sidebar/formulario.svg'
import iconOffshore from '../../assets/img/icons/sidebar/offshore.svg'
import iconEndividamento from '../../assets/img/icons/sidebar/endividamento.svg'
import iconOperacionais from '../../assets/img/icons/sidebar/operacionais.svg'
import iconMidiaDesabonadora from '../../assets/img/icons/sidebar/midia_desabonadora.svg'

import * as styles from './style.module.scss'

export default function Sidebar () {
  const { targetDocument } = useSelector(selectMinData)
  const location = useLocation()
  const history = useHistory()
  const { pages: enabledPages } = useSelector(selectSearchOptions)
  const basePath =
    StaticScopeMetadata.getAvailableApps().length > 1 ? '/dossie' : ''

  const options = [
    {
      label: 'Dados Básicos',
      icon: iconDadosBasicos,
      url: [`${basePath}/dados-basicos`, `${basePath}/`],
      active: enabledPages.dadosBasicos
    },
    {
      label: 'Grupo Societário',
      icon: iconGrupoSocietario,
      url: `${basePath}/grupo`,
      active: enabledPages.grupoSocietario
    },
    {
      label: 'Patrimônio',
      icon: iconPatrimonio,
      url: `${basePath}/patrimonio`,
      active: enabledPages.patrimonio
    },
    {
      label: 'Pontos de atenção',
      icon: iconBandeiraAmarela,
      url: `${basePath}/pontos-atencao`,
      active: enabledPages.pontosDeAtencao
    },
    StaticScopeMetadata.getAvailablePages().includes(
      EXCLUSIVE_CONTENTS.PAGES.FORMULARIO
    ) && {
      label: 'Formulário',
      icon: iconFormulario,
      url: `${basePath}/formulario`,
      active: !!targetDocument
    },
    {
      label: 'Offshore',
      icon: iconOffshore,
      url: `${basePath}/offshore`,
      active: enabledPages.offshore
    },
    {
      label: 'Endividamento',
      icon: iconEndividamento,
      url: `${basePath}/endividamento`,
      active: enabledPages.endividamento
    },
    {
      label: 'Mídia Desabonadora',
      icon: iconMidiaDesabonadora,
      url: `${basePath}/midia-desabonadora`,
      active: enabledPages.midiaDesabonadora
    },
    {
      label: 'Jurídico',
      icon: iconJuridico,
      url: `${basePath}/juridico`,
      active: enabledPages.juridico
    },
    {
      label: 'Operacionais',
      icon: iconOperacionais,
      url: `${basePath}/operacionais`,
      active: enabledPages.operacionais
    }
  ].filter(Boolean)

  return (
    <div
      className={`d-flex flex-column align-items-center ${styles.container}`}
    >
      <ReactTooltip />
      <div
        className={`d-flex flex-column justify-content-center ${styles.menuContainer}`}
      >
        <ul className={`ml-2 ${styles.menu}`}>
          {options.map(option => {
            const link = Array.isArray(option.url) ? option.url[0] : option.url
            const isCurrentPage = Array.isArray(option.url)
              ? option.url.includes(location.pathname)
              : location.pathname === option.url

            return (
              <li
                className={isCurrentPage ? styles.active : ''}
                key={option.url}
                data-tip={
                  option.active
                    ? option.label
                    : option.label + ' (Desabilitado)'
                }
                onClick={() => (option.active ? history.push(link) : null)}
                style={option.active ? {} : { opacity: 0.3 }}
              >
                <Link to={option.active ? link : '#'}>
                  <img src={option.icon} alt={option.label} height={20} />
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
