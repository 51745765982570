import React, { useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { formatDuration, intervalToDuration } from 'date-fns'

import { ptBR } from 'date-fns/locale'

import { ImHourGlass } from 'react-icons/im'
import { RiDownload2Line } from 'react-icons/ri'

import { BUILD_ENV, SCOPE } from '../../config/env'

import { selectMinData } from '../../redux/selectors/dadosBasicos'
import {
  selectJuridicoProgressStatus,
  selectProgressStatus
} from '../../redux/selectors/progressStatus'
import { gerarDocumento } from '../../redux/store/doc'
import { gerarRelatorioSulBrasil } from '../../redux/store/sulbrasil'

import * as styles from './style.module.scss'
import {
  closeProgressStatus,
  openProgressStatus
} from '../../redux/store/progressStatus'

import { PROGRESS_TYPES } from '../../lib/progressStatus'
import { EXCLUSIVE_CONTENTS, StaticScopeMetadata } from '../../lib/scope'

const resolveRelatorio = async () => {
  const dict = {
    [EXCLUSIVE_CONTENTS.FEATURES.REPORT_PAGE]: gerarRelatorioSulBrasil,
    [EXCLUSIVE_CONTENTS.FEATURES.DOCX]: gerarDocumento
  }

  if (StaticScopeMetadata.getAvailableFeatures()?.canSelectReportType) {
    const { isConfirmed, value } = await Swal.fire({
      title: 'Selecione o tipo de relatório',
      input: 'radio',
      inputOptions: {
        [EXCLUSIVE_CONTENTS.FEATURES.REPORT_PAGE]: 'Web (simplificado)',
        [EXCLUSIVE_CONTENTS.FEATURES.DOCX]: 'Word (docx)'
      },
      inputValidator: value => {
        if (!value) {
          return 'Selecione uma opção'
        }
      }
    })

    if (!isConfirmed) {
      return undefined
    }

    return dict[value]
  }

  const currentReport = StaticScopeMetadata.getAvailableFeatures()?.report

  return dict[currentReport]
}

const getEmoji = status => {
  if (status === 'unloaded') {
    return '❕'
  }

  if (status === 'loaded') {
    return '✔️'
  }

  if (status === 'error') {
    return '❌'
  }

  if (status === 'partialError') {
    return '⚠️'
  }

  return '🕑'
}

const formatDistanceLocale = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}m',
  xMonths: '{{count}}m',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y'
}

const formatDistance = (token, count, options) => {
  options = options || {}

  const result = formatDistanceLocale[token].replace('{{count}}', count)

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return 'in ' + result
    } else {
      return result + ' ago'
    }
  }

  return result
}

const Content = ({ title, subtitle, children }) => {
  return (
    <div className='container-fluid w-100'>
      <div className='mb-3'>
        <div className='d-flex flex-row'>
          <h1 className={`mb-0 ${styles.title} flex-grow-1`}>{title}</h1>
          <div className='ml-2 d-flex align-items-center'>
            <Options />
          </div>
        </div>
        <hr className='mt-1 mb-1' />
        <span className={`${styles.subtitle}`}>{subtitle}</span>
      </div>
      <div>{children}</div>
    </div>
  )
}

const Options = () => {
  const { targetName, targetDocument, isCPF } = useSelector(selectMinData)
  const progressStatus = useSelector(selectProgressStatus)
  const juridicoProgress = useSelector(selectJuridicoProgressStatus)
  const formularioSalvo = useSelector(state => state.sulbrasil.formularioSalvo)
  const metadata = useSelector(state => state.globalStep.metadata)
  const globalExecutionArn = metadata?.executionArn
  const executionId = globalExecutionArn
    ? globalExecutionArn.split(':').slice(-1)[0]
    : null
  const executionArnDocx = useSelector(state => state.doc.executionArn)
  const openProgressStatusAlert = useSelector(
    state => state.progressStatus.popupOpened
  )
  const targetType = isCPF ? 'pf' : 'pj'
  const juridicoProgressTarget = (juridicoProgress?.array || []).find(
    item =>
      item.document === targetDocument &&
      item.name === targetName &&
      item.type === targetType
  )
  const dispatch = useDispatch()

  const [openExcelAlert, setOpenExcelAlert] = useState(false)

  return (
    <>
      <button
        type='button'
        className='btn'
        style={{ color: 'black', background: 'none' }}
        data-tip='Ver detalhes do carregamento'
        onClick={() => {
          if (!targetDocument) {
            toast.warn('Selecione um CPF ou CNPJ', {
              position: toast.POSITION.BOTTOM_RIGHT,
              toastId: 'toast-progress-status-select-document'
            })
            return
          }
          dispatch(openProgressStatus())
        }}
        disabled={!targetDocument}
      >
        <ImHourGlass size={25} color='#0045c8' />
      </button>
      <button
        type='button'
        className='btn position-relative'
        style={{ color: 'black', background: 'none' }}
        data-tip='Gerar relatório'
        onClick={async () => {
          if (!targetDocument) {
            toast.warn('Selecione um CPF ou CNPJ', {
              position: toast.POSITION.BOTTOM_RIGHT,
              toastId: 'toast-progress-status-select-document'
            })
            return
          }

          const gerarRelatorio = await resolveRelatorio()

          if (gerarRelatorio) {
            dispatch(gerarRelatorio())
          }
        }}
        disabled={!targetDocument || !metadata.finishedAt}
      >
        <RiDownload2Line color='#0045c8' size={25} />
        {!formularioSalvo && (
          <span
            className='position-absolute p-1 bg-warning rounded-circle'
            style={{
              top: 0,
              fontSize: 18,
              fontWeight: 'bold',
              width: 16,
              height: 16,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              right: 1
            }}
          >
            <span className='text-light small'>!</span>
            <span className='sr-only'>New alerts</span>
          </span>
        )}
      </button>
      <Modal
        show={openProgressStatusAlert}
        onHide={() => dispatch(closeProgressStatus())}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Status de carregamento dos dados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {SCOPE !== 'boanota' && (
            <>
              <div className='row'>
                <div className='col-12 text-center'>Global</div>
                <div className='col-12 mb-3'>
                  <div className='progress'>
                    <div
                      className={`progress-bar progress-bar-striped ${
                        progressStatus.visible ? 'progress-bar-animated' : ''
                      } bg-success`}
                      role='progressbar'
                      style={{ width: `${progressStatus.progressLoaded}%` }}
                      aria-valuenow={progressStatus.progressLoaded}
                      aria-valuemin='0'
                      aria-valuemax='100'
                    />
                    <div
                      className={`progress-bar progress-bar-striped ${
                        progressStatus.visible ? 'progress-bar-animated' : ''
                      } bg-warning`}
                      role='progressbar'
                      style={{
                        width: `${progressStatus.progressPartialError}%`
                      }}
                      aria-valuenow={progressStatus.progressPartialError}
                      aria-valuemin='0'
                      aria-valuemax='100'
                    />
                    <div
                      className={`progress-bar progress-bar-striped ${
                        progressStatus.visible ? 'progress-bar-animated' : ''
                      } bg-danger`}
                      role='progressbar'
                      style={{ width: `${progressStatus.progressError}%` }}
                      aria-valuenow={progressStatus.progressError}
                      aria-valuemin='0'
                      aria-valuemax='100'
                    />
                  </div>
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-12 text-center'>Jurídico</div>
                <div className='col-12 mb-3'>
                  <div className='progress'>
                    <div
                      className={`progress-bar progress-bar-striped ${
                        juridicoProgress.visible ? 'progress-bar-animated' : ''
                      } bg-success`}
                      role='progressbar'
                      style={{ width: `${juridicoProgress.progressLoaded}%` }}
                      aria-valuenow={juridicoProgress.progressLoaded}
                      aria-valuemin='0'
                      aria-valuemax='100'
                    />
                    <div
                      className={`progress-bar progress-bar-striped ${
                        juridicoProgress.visible ? 'progress-bar-animated' : ''
                      } bg-danger`}
                      role='progressbar'
                      style={{ width: `${juridicoProgress.progressError}%` }}
                      aria-valuenow={juridicoProgress.progressError}
                      aria-valuemin='0'
                      aria-valuemax='100'
                    />
                  </div>
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-12 text-center'>Jurídico - Target</div>
                <div className='col-12'>
                  <div className='progress'>
                    <div
                      className={`progress-bar progress-bar-striped ${
                        juridicoProgress.visible ? 'progress-bar-animated' : ''
                      } ${
                        juridicoProgressTarget?.status === PROGRESS_TYPES.ERROR
                          ? 'bg-danger'
                          : 'bg-success'
                      }`}
                      role='progressbar'
                      style={{
                        width: `${
                          !juridicoProgress.visible &&
                          juridicoProgressTarget?.status ===
                            PROGRESS_TYPES.PENDING
                            ? '0'
                            : [
                                PROGRESS_TYPES.ERROR,
                                PROGRESS_TYPES.LOADED
                              ].includes(juridicoProgressTarget?.status)
                            ? '100'
                            : '0'
                        }%`
                      }}
                      aria-valuenow={10}
                      aria-valuemin='0'
                      aria-valuemax='100'
                    />
                  </div>
                </div>
              </div>
              <hr className='mt-3 mb-3' />
            </>
          )}
          {BUILD_ENV === 'dev' && (
            <>
              <div className='row mb-3'>
                <div className='col-4'>
                  <span>
                    Início: {metadata.startedAt?.toLocaleTimeString() ?? '-'}
                  </span>
                </div>
                <div className='col-4'>
                  <span>
                    Fim: {metadata.finishedAt?.toLocaleTimeString() ?? '-'}
                  </span>
                </div>
                <div className='col-4'>
                  <span>
                    Duração:{' '}
                    {metadata.finishedAt
                      ? formatDuration(
                          intervalToDuration({
                            start: metadata.startedAt,
                            end: metadata.finishedAt
                          }),
                          { locale: { ...ptBR, formatDistance } }
                        )
                      : '-'}
                  </span>
                </div>
                <div className='col-12'>
                  <span>
                    Link da execução:{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href={`https://us-east-1.console.aws.amazon.com/states/home?region=us-east-1#/executions/details/${metadata.executionArn}`}
                    >
                      clique aqui
                    </a>
                  </span>
                </div>
                {executionArnDocx && (
                  <div className='col-12'>
                    <span>
                      Link da execução do DOCX:{' '}
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={`https://us-east-1.console.aws.amazon.com/states/home?region=us-east-1#/executions/details/${executionArnDocx}`}
                      >
                        clique aqui
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
          <span>
            Identificador da consulta: <strong>{executionId || '-'}</strong>
          </span>
          <hr className='mt-3 mb-3' />
          {SCOPE === 'boanota' && progressStatus.progressPending > 0 ? (
            <div className='row'>
              <div className='col-12 text-center p-3'>
                <Spinner animation='border' variant='primary' />
              </div>
            </div>
          ) : (
            <div className='row'>
              {progressStatus.array.map(item => {
                return (
                  <div
                    className='col-6'
                    key={`${item.raw}-${item.text}-${item.status}`}
                    aria-label={item.raw}
                  >
                    <span>{getEmoji(item.status)}</span> {item.text}
                  </div>
                )
              })}
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={openExcelAlert}
        onHide={() => setOpenExcelAlert(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Status de carregamento dos dados</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={() => {
              setOpenExcelAlert(false)
            }}
          >
            Gerar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Content
