import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { toast } from 'react-toastify'
import { Form, Modal } from 'react-bootstrap'

import { selectMinData } from '../../../redux/selectors/dadosBasicos'
import { selectSearchOptions } from '../../../redux/selectors/searchOptions'
import { selectRelateds } from '../../../redux/selectors/relateds'

import { Content, CustomWindow, Loading } from '../../../components'
import Table from '../../../components/Table'
import {
  onSortDate,
  onSortNumber,
  onSortDocument
} from '../../../components/Table/sort'
import DynamicGoogleMaps from '../../../components/DynamicGoogleMaps'

import '../../../assets/sass/patrimonio.scss'
import {
  factoryDate,
  formatDate,
  formatDocument,
  hashObject,
  isPending,
  onlyNumbers
} from '../../../utils'
import { fetchPatrimonioFromS3 } from '../../../lib/s3Theme'
import mapWithRelationship from '../../../lib/detectRelationship'

const { SearchBar } = Search

export default function Patrimonio () {
  const patrimonioTheme = useSelector(state => state.patrimonioTheme)
  const { targetName, targetDocument } = useSelector(selectMinData)
  const { pages: enabledPages } = useSelector(selectSearchOptions)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    aeronaves: { data: [], loaded: false, error: false },
    sigef: { data: [], loaded: false, error: false },
    ibama: { data: [], loaded: false, error: false },
    sncr: { data: [], loaded: false, error: false },
    inpi: {
      data: {
        contratoTecnologia: [],
        desenhoIndustrial: [],
        patentes: [],
        programaComputador: [],
        registroMarcas: []
      },
      loaded: false,
      error: false
    }
  })

  useEffect(() => {
    if (
      isPending(patrimonioTheme.sncr) &&
      isPending(patrimonioTheme.aeronaves) &&
      isPending(patrimonioTheme.sigef) &&
      isPending(patrimonioTheme.ibama) &&
      isPending(patrimonioTheme.inpi)
    ) {
      return
    }

    setLoading(true)
    fetchPatrimonioFromS3({
      aeronavesPayload: patrimonioTheme.aeronaves,
      sigefPayload: patrimonioTheme.sigef,
      ibamaPayload: patrimonioTheme.ibama,
      sncrPayload: patrimonioTheme.sncr,
      inpiPayload: patrimonioTheme.inpi
    })
      .then(patrimonio => {
        setData(patrimonio)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          aeronaves: { data: [], loaded: false, error: true },
          sigef: { data: [], loaded: false, error: true },
          ibama: { data: [], loaded: false, error: true },
          sncr: { data: [], loaded: false, error: true },
          inpi: {
            data: {
              contratoTecnologia: [],
              desenhoIndustrial: [],
              patentes: [],
              programaComputador: [],
              registroMarcas: []
            },
            loaded: false,
            error: true
          }
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [patrimonioTheme])

  const isStepPending =
    targetDocument &&
    (isPending(patrimonioTheme.aeronaves) ||
      isPending(patrimonioTheme.sncr) ||
      isPending(patrimonioTheme.sigef) ||
      isPending(patrimonioTheme.ibama) ||
      isPending(patrimonioTheme.inpi))

  return (
    <Content
      title={`Patrimônio ${targetName ? `- ${targetName}` : ''}`}
      subtitle='Busca por dados patrimonias na base de dados de Aeronaves, Imóveis Rurais e no INPI.'
    >
      {!targetDocument ? null : !enabledPages.patrimonio ? (
        <div className='d-flex w-100 h-100 flex-1 justify-content-center align-items-center'>
          <p className='h3'>Esta aba está desabilitada!</p>
        </div>
      ) : loading || isStepPending ? (
        <Loading />
      ) : (
        <>
          <Aeronaves aeronaves={data.aeronaves} />
          <ImoveisRurais sncr={data.sncr} sigef={data.sigef} />
          <CertificadoImoveisAmbientais ibama={data.ibama} />
          <ContratoTecnologia inpi={data.inpi} />
          <DesenhoIndustrial inpi={data.inpi} />
          <ProgramaComputador inpi={data.inpi} />
          <Patentes inpi={data.inpi} />
          <RegistroMarcas inpi={data.inpi} />
        </>
      )}
    </Content>
  )
}

const Aeronaves = React.memo(({ aeronaves }) => {
  const [showFull, setShowFull] = useState(false)
  const { isCPF, targetDocument, targetName } = useSelector(selectMinData)
  const relateds = useSelector(selectRelateds)

  const name = 'patrimonio.aeronaves'

  if (isPending(aeronaves)) {
    return <div />
  }

  if (aeronaves.error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <CustomWindow
          title={`Aeronaves ${targetName ? `- ${targetName}` : ''}`}
          subtitle={`${
            targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
          } ${formatDocument(targetDocument)}`}
          name={name}
          showNote
        >
          <div>
            Ops! Aconteceu um erro e não foi possível carregar as aeronaves
          </div>
        </CustomWindow>
      </div>
    )
  }

  return (
    <ToolkitProvider
      keyField='id'
      data={mapWithRelationship({
        array: aeronaves.data || [],
        documentKey: 'cpfCnpj',
        nameKey: 'proprietario',
        relateds
      }).map((aeronave, key) => {
        const id = hashObject({ aeronave, key })
        return {
          ...aeronave,
          id,
          grau: aeronave.relationship,
          cpfCnpj: aeronave.cpfCnpj ? (
            <a
              href={`?documento=${onlyNumbers(aeronave.cpfCnpj)}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {aeronave.cpfCnpj}
            </a>
          ) : (
            '-'
          )
        }
      })}
      columns={[
        { dataField: 'marca', text: 'Marca', sort: true },
        { dataField: 'nmFabricante', text: 'Fabricante', sort: true },
        { dataField: 'dsModelo', text: 'Modelo', sort: true },
        { dataField: 'nmOperador', text: 'Operador', sort: true },
        { dataField: 'nrAnoFabricacao', text: 'Ano', sort: true },
        { dataField: 'nrAssentos', text: 'Assentos', sort: true },
        { dataField: 'nrSerie', text: 'Serie' },
        { dataField: 'nrTripulacaoMin', text: 'Tripulação' },
        { dataField: 'proprietario', text: 'Proprietário' },
        {
          dataField: 'grau',
          text: 'Grau',
          sort: true
        },
        { dataField: 'sgUf', text: 'UF' },
        ...(showFull
          ? [
              { dataField: 'cdCategoria', text: 'cat' },
              { dataField: 'cdInterdicao', text: 'Interdição' },
              { dataField: 'cdMarcaEstrangeira', text: 'Estr.' },
              { dataField: 'cdMarcaNac1', text: 'Nac. 1' },
              { dataField: 'cdMarcaNac2', text: 'Nac. 2' },
              { dataField: 'cdMarcaNac3', text: 'Nac. 3' },
              { dataField: 'cdTipo', text: 'Tipo' },
              { dataField: 'cdTipoIcao', text: 'Tipo icao' },
              { dataField: 'cpfCgc', text: 'CPF/cgc' },
              { dataField: 'cpfCnpj', text: 'CPF/CNPJ' },
              { dataField: 'dataDados', text: 'Data' },
              { dataField: 'dsGravame', text: 'Gravame' },
              { dataField: 'dsMotivoCanc', text: 'Motiv. canc.' },
              { dataField: 'dtCanc', text: 'Canc' },
              { dataField: 'dtValidadeCa', text: 'Validade CA' },
              { dataField: 'dtValidadeIam', text: 'Validade IAM' },
              { dataField: 'nrCertMatricula', text: 'Cert. Matrícula' },
              { dataField: 'nrPassageirosMax', text: 'N. Pass.' },
              { dataField: 'nrPmd', text: 'PMD' },
              { dataField: 'outrosOperadores', text: 'Outros Operadores' },
              {
                dataField: 'outrosProprietarios',
                text: 'Outros Proprietários'
              },
              { dataField: 'ufOperador', text: 'UF Operador' }
            ]
          : [])
      ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
      search
    >
      {props => (
        <CustomWindow
          title={`Aeronaves ${targetName ? `- ${targetName}` : ''}`}
          subtitle={`${
            targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
          } ${formatDocument(targetDocument)}`}
          name={name}
          showNote
        >
          <div className='row'>
            <div className='col-12 col-lg-6'>
              <button
                type='button'
                className='btn btn-sm btn-outline-primary mb-3'
                onClick={() => setShowFull(!showFull)}
              >
                {showFull ? 'Modo Compacto' : 'Modo Completo'}
              </button>
            </div>
            <div className='col-12 col-lg-6 text-lg-right'>
              <SearchBar {...props.searchProps} placeholder='Pesquisar' />
            </div>
          </div>

          <Table {...props.baseProps} enablePagination />
        </CustomWindow>
      )}
    </ToolkitProvider>
  )
})

const ImoveisRurais = React.memo(({ sncr, sigef }) => {
  const relateds = useSelector(selectRelateds)
  const [selectedItem, setSelectedItem] = useState({
    open: false,
    item: null
  })
  const name = 'patrimonio.imoveisRurais'
  const subtitle =
    'Busca suscetível a homônimos. Dados retirados do Indrema Datalake.'

  const [selectedTable, setSelectedTable] = useState('sncr')

  const error = sncr.error
  if (isPending(sncr)) {
    return <div />
  }

  if (error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <CustomWindow
          title='Imóveis Rurais'
          subtitle={subtitle}
          name={name}
          showNote
        >
          <div>
            Ops! Aconteceu um erro ao carregar as informações dos imóveis rurais
          </div>
        </CustomWindow>
      </div>
    )
  }

  const tableData =
    selectedTable === 'sncr'
      ? mapWithRelationship({
          array: sncr.data || [],
          nameKey: 'titular',
          relateds
        }).map((imovel, key) => {
          const id = hashObject({ imovel, key })
          return {
            id,
            areatotal: imovel.areatotal,
            titular: imovel.titular,
            percentualdedetencao: imovel.percentualdedetencao,
            codigodoimovel: imovel.codigodoimovel,
            condicaodapessoa: imovel.condicaodapessoa,
            denominacaodoimovel: imovel.denominacaodoimovel,
            municipio: imovel.municipio,
            grau: imovel.relationship,
            uf: imovel.uf,
            matching: imovel.matching,
            action: imovel
          }
        })
      : mapWithRelationship({
          array: sigef.data || [],
          documentKey: 'document',
          relateds
        }).map((imovel, key) => {
          const id = hashObject({ imovel, key })
          return {
            id,
            ...imovel,
            grau: imovel.relationship,
            detentores: imovel.detentor.join(', ')
          }
        })

  const columns =
    selectedTable === 'sncr'
      ? [
          { dataField: 'areatotal', text: 'Área Total (hectares)', sort: true },
          { dataField: 'titular', text: 'Títular', sort: true },
          {
            dataField: 'grau',
            text: 'Grau'
          },
          { dataField: 'percentualdedetencao', text: '%', sort: true },
          { dataField: 'codigodoimovel', text: 'Cód. do Imóvel', sort: true },
          { dataField: 'condicaodapessoa', text: 'Condição', sort: true },
          { dataField: 'denominacaodoimovel', text: 'Denominação', sort: true },
          { dataField: 'municipio', text: 'Município', sort: true },
          { dataField: 'uf', text: 'UF', sort: true },
          { dataField: 'matching', text: 'Matching', sort: true },
          {
            dataField: 'actions',
            text: '',
            sort: false,
            formatter: (_, row) => {
              const item = row.action

              if (!item.geom) {
                return <span />
              }

              return (
                <span>
                  <button
                    type='button'
                    className='btn btn-primary btn-sm'
                    onClick={() => setSelectedItem({ open: true, item })}
                  >
                    geolocalização
                  </button>
                </span>
              )
            }
          }
        ]
      : [
          { dataField: 'area', text: 'Área Total (hectares)', sort: true },
          { text: 'Proprietários', dataField: 'detentor', sort: true },
          {
            dataField: 'grau',
            text: 'Grau'
          },
          { text: 'Propriedade', dataField: 'nome', sort: true },
          { text: 'Matrícula', dataField: 'matricula', sort: true },
          {
            text: 'CNS',
            dataField: 'cns',
            sort: true
          }
        ]

  return (
    <>
      <Modal
        show={selectedItem.open}
        onHide={() => setSelectedItem({ open: false, item: null })}
        centered
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>Geolocalização</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-12 text-center'>
              <DynamicGoogleMaps
                geoJson={selectedItem?.item?.geom}
                mapContainerStyle={{
                  width: '100%',
                  height: '400px'
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToolkitProvider
        keyField='id'
        columns={columns.map(item => ({
          ...item,
          align: 'center',
          headerAlign: 'center'
        }))}
        data={tableData || []}
        search
      >
        {props => (
          <CustomWindow
            title='Imóveis Rurais'
            subtitle={subtitle}
            name={name}
            showNote
          >
            <div className='row mb-2'>
              <div className='col-12 col-lg-6 d-flex flex-row gap-3 mb-3'>
                <Form.Check
                  type='radio'
                  label='Cadastro rural'
                  onChange={() => setSelectedTable('sncr')}
                  checked={selectedTable === 'sncr'}
                  disabled={!sncr.loaded || sncr.error}
                />

                <Form.Check
                  type='radio'
                  label='Ibama'
                  onChange={() => setSelectedTable('sigef')}
                  checked={selectedTable === 'sigef'}
                  disabled={!sigef.loaded || sigef.error}
                />
              </div>
              <div className='col-12 col-lg-6 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Table enablePagination {...props.baseProps} />
              </div>
            </div>
          </CustomWindow>
        )}
      </ToolkitProvider>
    </>
  )
})

const CertificadoImoveisAmbientais = React.memo(({ ibama }) => {
  const relateds = useSelector(selectRelateds)
  const name = 'patrimonio.imoveisAmbiental'
  const title = 'Certificado de Regularidade Ambiental'
  const subtitle = 'Dados retirados do Ibama'

  if (isPending(ibama)) {
    return <div />
  }

  if (ibama.error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <CustomWindow title={title} name={name} subtitle={subtitle} showNote>
          <div>
            Ops! Aconteceu um erro e por isso não foi possível carregar os
            certificados dos imóveis ambientais
          </div>
        </CustomWindow>
      </div>
    )
  }

  return (
    <ToolkitProvider
      keyField='id'
      columns={[
        {
          dataField: 'documento',
          text: 'CPF / CNPJ',
          sort: true,
          sortFunc: onSortDocument
        },
        { dataField: 'nomePessoa', text: 'Nome', sort: true },
        {
          dataField: 'grau',
          text: 'Grau'
        },
        {
          dataField: 'numeroRegistro',
          text: 'Registro',
          sort: true,
          sortFunc: onSortNumber
        },
        {
          dataField: 'status',
          text: 'Status',
          sort: true
        },
        {
          dataField: 'dataConsulta',
          text: 'Data Consulta',
          sort: true,
          sortFunc: onSortDate
        }
      ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
      data={mapWithRelationship({
        array: ibama.data || [],
        documentKey: 'cpfCnpj',
        nameKey: 'nomeRazaoSocial',
        relateds
      }).map((imovel, key) => {
        const id = hashObject({ imovel, key })
        return {
          id,
          documento: imovel.cpfCnpj ? (
            <a
              href={`?documento=${onlyNumbers(imovel.cpfCnpj)}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {formatDocument(imovel.cpfCnpj)}
            </a>
          ) : (
            '-'
          ),
          grau: imovel.relationship,
          nomePessoa: imovel.nomeRazaoSocial,
          dataConsulta: imovel.dataConsulta
            ? formatDate(factoryDate(imovel.dataConsulta))
            : '-',
          dataEmissao: imovel.dataEmissao
            ? formatDate(factoryDate(imovel.dataEmissao))
            : '-',
          dataValidade: imovel.dataValidade
            ? formatDate(factoryDate(imovel.dataValidade))
            : '-',
          numeroRegistro: imovel.numeroRegistro ?? '-',
          status: imovel.status ?? '-'
        }
      })}
      search
    >
      {props => (
        <CustomWindow title={title} name={name} subtitle={subtitle} showNote>
          <div className='col-12 text-lg-right'>
            <SearchBar {...props.searchProps} placeholder='Pesquisar' />
          </div>
          <Table enablePagination {...props.baseProps} />
        </CustomWindow>
      )}
    </ToolkitProvider>
  )
})

const ContratoTecnologia = React.memo(({ inpi }) => {
  const relateds = useSelector(selectRelateds)

  const name = 'patrimonio.contratoTecnologia'
  const title = 'Contratos de tecnologia'
  const subtitle = 'Busca suscetível a homônimos. Dados retirados do INPI'

  if (isPending(inpi)) {
    return <div />
  }

  if (inpi.error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <CustomWindow title={title} subtitle={subtitle} name={name} showNote>
          <div>
            Ops! Aconteceu um erro e não foi possível carregar os contratos de
            tecnologia.
          </div>
        </CustomWindow>
      </div>
    )
  }

  const tableData = inpi.data.contratoTecnologia || []

  return (
    <ToolkitProvider
      keyField='id'
      data={mapWithRelationship({
        array: tableData || [],
        nameKey: 'nomeBuscado',
        relateds
      }).map((item, key) => {
        const id = hashObject({ item, key })
        const dataPublicacaoRevista = formatDate(
          factoryDate(item.dataPublicacaoRevista)
        )
        return {
          ...item,
          id,
          grau: item.relationship,
          dataPublicacaoRevista
        }
      })}
      columns={[
        { dataField: 'cedente', text: 'Nome', sort: true },
        {
          dataField: 'grau',
          text: 'Grau',
          sort: true
        },
        { dataField: 'titulo', text: 'Título', sort: true },
        { dataField: 'numeroRevista', text: 'Nº revista', sort: true },
        {
          dataField: 'dataPublicacaoRevista',
          text: 'Data de publicação',
          sort: true,
          sortFunc: onSortDate
        },
        { dataField: 'codigo', text: 'Código', sort: true },
        { dataField: 'cessionaria', text: 'Cessionária', sort: true },
        { dataField: 'nmProcesso', text: 'Processo', sort: true }
      ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
      search
    >
      {props => (
        <CustomWindow title={title} subtitle={subtitle} name={name} showNote>
          <>
            <div className='row'>
              <div className='col-12 col-lg-12 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <Table {...props.baseProps} enablePagination />
          </>
        </CustomWindow>
      )}
    </ToolkitProvider>
  )
})

const DesenhoIndustrial = React.memo(({ inpi }) => {
  const relateds = useSelector(selectRelateds)

  const name = 'patrimonio.desenhoIndustrial'
  const title = 'Desenhos industriais'
  const subtitle = 'Busca suscetível a homônimos. Dados retirados do INPI'

  if (isPending(inpi)) {
    return <div />
  }

  if (inpi.error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <CustomWindow title={title} subtitle={subtitle} name={name} showNote>
          <div>
            Ops! Aconteceu um erro e não foi possível carregar os desenhos
            industriais.
          </div>
        </CustomWindow>
      </div>
    )
  }

  const tableData = inpi.data.desenhoIndustrial || []

  return (
    <ToolkitProvider
      keyField='id'
      data={mapWithRelationship({
        array: tableData || [],
        nameKey: 'nomeBuscado',
        relateds
      }).map((item, key) => {
        const id = hashObject({ item, key })
        const dataPublicacaoRevista = formatDate(
          factoryDate(item.dataPublicacaoRevista)
        )
        const dataDeposito = item.dataDeposito
          ? formatDate(factoryDate(item.dataDeposito))
          : ''
        const dataConcessao = item.dataConcessao
          ? formatDate(factoryDate(item.dataConcessao))
          : ''
        return {
          ...item,
          id,
          grau: item.relationship,
          dataPublicacaoRevista,
          dataConcessao,
          dataDeposito
        }
      })}
      columns={[
        { dataField: 'titular', text: 'Nome', sort: true },
        {
          dataField: 'grau',
          text: 'Grau',
          sort: true
        },
        { dataField: 'titulo', text: 'Título', sort: true },
        { dataField: 'numeroRevista', text: 'Nº revista', sort: true },
        {
          dataField: 'dataPublicacaoRevista',
          text: 'Data de publicação',
          sort: true,
          sortFunc: onSortDate
        },
        { dataField: 'codigo', text: 'Código', sort: true },
        { dataField: 'nmProcesso', text: 'Processo', sort: true },
        {
          dataField: 'dataDeposito',
          text: 'Data de depósito',
          sort: true,
          sortFunc: onSortDate
        },
        {
          dataField: 'dataConcessao',
          text: 'Data de concessão',
          sort: true,
          sortFunc: onSortDate
        },
        {
          dataField: 'classificacaoNacional',
          text: 'Classificação',
          sort: true
        },
        { dataField: 'inventor', text: 'Inventor', sort: true },
        { dataField: 'procurador', text: 'Procurador', sort: true },
        { dataField: 'descricao', text: 'Descrição', sort: true },
        { dataField: 'comentario', text: 'Comentário', sort: true }
      ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
      search
    >
      {props => (
        <CustomWindow title={title} subtitle={subtitle} name={name} showNote>
          <>
            <div className='row'>
              <div className='col-12 col-lg-12 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <Table {...props.baseProps} enablePagination />
          </>
        </CustomWindow>
      )}
    </ToolkitProvider>
  )
})

const ProgramaComputador = React.memo(({ inpi }) => {
  const relateds = useSelector(selectRelateds)

  const name = 'patrimonio.programaComputador'
  const title = 'Programas de computador'
  const subtitle = 'Busca suscetível a homônimos. Dados retirados do INPI'

  if (isPending(inpi)) {
    return <div />
  }

  if (inpi.error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <CustomWindow title={title} subtitle={subtitle} name={name} showNote>
          <div>
            Ops! Aconteceu um erro e não foi possível carregar os programas de
            computador.
          </div>
        </CustomWindow>
      </div>
    )
  }

  const tableData = inpi.data.programaComputador || []

  return (
    <ToolkitProvider
      keyField='id'
      data={mapWithRelationship({
        array: tableData || [],
        nameKey: 'nomeBuscado',
        relateds
      }).map((item, key) => {
        const id = hashObject({ item, key })
        const dataPublicacaoRevista = formatDate(
          factoryDate(item.dataPublicacaoRevista)
        )
        const dataCriacao = item.dataCriacao
          ? formatDate(factoryDate(item.dataCriacao))
          : ''
        return {
          ...item,
          id,
          grau: item.relationship,
          dataPublicacaoRevista,
          dataCriacao
        }
      })}
      columns={[
        { dataField: 'titular', text: 'Nome', sort: true },
        {
          dataField: 'grau',
          text: 'Grau',
          sort: true
        },
        { dataField: 'titulo', text: 'Título', sort: true },
        { dataField: 'tituloPrograma', text: 'Título do programa', sort: true },
        { dataField: 'criador', text: 'Criador', sort: true },
        { dataField: 'numeroRevista', text: 'Nº revista', sort: true },
        {
          dataField: 'dataPublicacaoRevista',
          text: 'Data de publicação',
          sort: true,
          sortFunc: onSortDate
        },
        { dataField: 'codigo', text: 'Código', sort: true },
        { dataField: 'nmProcesso', text: 'Processo', sort: true },
        {
          dataField: 'dataCriacao',
          text: 'Data de criação',
          sort: true,
          sortFunc: onSortDate
        },
        { dataField: 'campoAplicacao', text: 'Campo de aplicação', sort: true },
        { dataField: 'linguagem', text: 'Linguagem', sort: true },
        { dataField: 'tipoPrograma', text: 'Tipo de programa', sort: true }
      ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
      search
    >
      {props => (
        <CustomWindow title={title} subtitle={subtitle} name={name} showNote>
          <>
            <div className='row'>
              <div className='col-12 col-lg-12 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <Table {...props.baseProps} enablePagination />
          </>
        </CustomWindow>
      )}
    </ToolkitProvider>
  )
})

const Patentes = React.memo(({ inpi }) => {
  const relateds = useSelector(selectRelateds)

  const name = 'patrimonio.patentes'
  const title = 'Patentes'
  const subtitle = 'Busca suscetível a homônimos. Dados retirados do INPI'

  if (isPending(inpi)) {
    return <div />
  }

  if (inpi.error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <CustomWindow title={title} subtitle={subtitle} name={name} showNote>
          <div>
            Ops! Aconteceu um erro e não foi possível carregar as patentes.
          </div>
        </CustomWindow>
      </div>
    )
  }

  const tableData = inpi.data.patentes || []

  return (
    <ToolkitProvider
      keyField='id'
      data={mapWithRelationship({
        array: tableData || [],
        nameKey: 'nomeBuscado',
        relateds
      }).map((item, key) => {
        const id = hashObject({ item, key })
        const dataPublicacaoRevista = formatDate(
          factoryDate(item.dataPublicacaoRevista)
        )
        const dataDeposito = item.dataDeposito
          ? formatDate(factoryDate(item.dataDeposito))
          : ''
        const dataRpi = item.dataRpi
          ? formatDate(factoryDate(item.dataRpi))
          : ''
        return {
          ...item,
          id,
          grau: item.relationship,
          dataPublicacaoRevista,
          dataRpi,
          dataDeposito
        }
      })}
      columns={[
        { dataField: 'titular', text: 'Nome', sort: true },
        {
          dataField: 'grau',
          text: 'Grau',
          sort: true
        },
        { dataField: 'titulo', text: 'Título', sort: true },
        { dataField: 'tituloPatente', text: 'Título patente', sort: true },
        { dataField: 'numeroRevista', text: 'Nº revista', sort: true },
        {
          dataField: 'dataPublicacaoRevista',
          text: 'Data de publicação',
          sort: true,
          sortFunc: onSortDate
        },
        { dataField: 'codigo', text: 'Código', sort: true },
        { dataField: 'nmProcesso', text: 'Processo', sort: true },
        {
          dataField: 'dataDeposito',
          text: 'Data de depósito',
          sort: true,
          sortFunc: onSortDate
        },
        {
          dataField: 'dataRpi',
          text: 'Data RPI',
          sort: true,
          sortFunc: onSortDate
        },
        {
          dataField: 'classificacaoInternacional',
          text: 'Classificação',
          sort: true
        },
        { dataField: 'inventor', text: 'Inventor', sort: true },
        { dataField: 'comentario', text: 'Comentário', sort: true }
      ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
      search
    >
      {props => (
        <CustomWindow title={title} subtitle={subtitle} name={name} showNote>
          <>
            <div className='row'>
              <div className='col-12 col-lg-12 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <Table {...props.baseProps} enablePagination />
          </>
        </CustomWindow>
      )}
    </ToolkitProvider>
  )
})

const RegistroMarcas = React.memo(({ inpi }) => {
  const relateds = useSelector(selectRelateds)

  const name = 'patrimonio.registroMarcas'
  const title = 'Registro de marcas'
  const subtitle = 'Busca suscetível a homônimos. Dados retirados do INPI'

  if (isPending(inpi)) {
    return <div />
  }

  if (inpi.error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <CustomWindow title={title} subtitle={subtitle} name={name} showNote>
          <div>
            Ops! Aconteceu um erro e não foi possível carregar os registros de
            marcas.
          </div>
        </CustomWindow>
      </div>
    )
  }

  const tableData = inpi.data.registroMarcas || []

  return (
    <ToolkitProvider
      keyField='id'
      data={mapWithRelationship({
        array: tableData || [],
        nameKey: 'nomeBuscado',
        relateds
      }).map((item, key) => {
        const id = hashObject({ item, key })
        const dataPublicacaoRevista = formatDate(
          factoryDate(item.dataPublicacaoRevista)
        )
        const dataDeposito = item.dataDeposito
          ? formatDate(factoryDate(item.dataDeposito))
          : ''
        const dataConcessao = item.dataConcessao
          ? formatDate(factoryDate(item.dataConcessao))
          : ''
        const dataVigencia = item.dataVigencia
          ? formatDate(factoryDate(item.dataVigencia))
          : ''
        return {
          ...item,
          id,
          grau: item.relationship,
          dataPublicacaoRevista,
          dataConcessao,
          dataDeposito,
          dataVigencia
        }
      })}
      columns={[
        { dataField: 'nomeTitular', text: 'Nome', sort: true },
        {
          dataField: 'grau',
          text: 'Grau',
          sort: true
        },
        { dataField: 'numeroRevista', text: 'Nº revista', sort: true },
        {
          dataField: 'dataPublicacaoRevista',
          text: 'Data de publicação',
          sort: true,
          sortFunc: onSortDate
        },
        { dataField: 'codigoDespacho', text: 'Código despacho', sort: true },
        { dataField: 'nmProcesso', text: 'Processo', sort: true },
        {
          dataField: 'dataDeposito',
          text: 'Data de depósito',
          sort: true,
          sortFunc: onSortDate
        },
        {
          dataField: 'dataConcessao',
          text: 'Data de concessão',
          sort: true,
          sortFunc: onSortDate
        },
        {
          dataField: 'dataVigencia',
          text: 'Data de vigência',
          sort: true,
          sortFunc: onSortDate
        },
        { dataField: 'nomeDespacho', text: 'Nome despacho', sort: true },
        { dataField: 'paisTitular', text: 'País', sort: true },
        { dataField: 'ufTitular', text: 'UF', sort: true },
        { dataField: 'apresentacaoMarca', text: 'Apresentação', sort: true },
        { dataField: 'naturezamarca', text: 'Natureza', sort: true },
        { dataField: 'nomeMarca', text: 'Marca', sort: true },
        { dataField: 'codigoNice', text: 'Código Nice', sort: true },
        {
          dataField: 'especificacaoNice',
          text: 'Especificação Nice',
          sort: true
        },
        { dataField: 'statusNice', text: 'Status Nice', sort: true },
        { dataField: 'procurador', text: 'Procurador', sort: true }
      ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
      search
    >
      {props => (
        <CustomWindow title={title} subtitle={subtitle} name={name} showNote>
          <>
            <div className='row'>
              <div className='col-12 col-lg-12 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <Table {...props.baseProps} enablePagination />
          </>
        </CustomWindow>
      )}
    </ToolkitProvider>
  )
})
