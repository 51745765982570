import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import Swal from 'sweetalert2'

import { FiHelpCircle } from 'react-icons/fi'
import { MdAttachMoney } from 'react-icons/md'

import API from '../../services/api'

import { selectMinData } from '../../redux/selectors/dadosBasicos'

import { SEARCH_OPTIONS } from '../../config/consts'
import { SCOPE } from '../../config/env'

import searchDocument from '../../lib/searchDocument'
import { EXCLUSIVE_CONTENTS, StaticScopeMetadata } from '../../lib/scope'

import {
  formatDecimal,
  formatDocument,
  formatPartialCnpj,
  formatPartialCpf,
  getCurrentUser,
  isCPF
} from '../../utils'
import { useCredits } from '../../utils/hooks'

const Search = ({ onSearch }) => {
  const { targetDocument } = useSelector(selectMinData)
  const history = useHistory()
  const { credits, isError, isLoading } = useCredits(SCOPE === 'boanota')

  const [documento, setDocumento] = useState('')
  const [loadedDocumento, setLoadedDocumento] = useState(false)
  const [options, setOptions] = useState([SEARCH_OPTIONS[0]])
  const providers = StaticScopeMetadata.getAvailableProviders()

  const dispatch = useDispatch()

  useEffect(() => {
    if (
      documento.length > 0 ||
      loadedDocumento ||
      (targetDocument || '').length === 0
    ) {
      return
    }

    setLoadedDocumento(true)
    setDocumento(formatDocument(targetDocument))
  }, [documento, targetDocument, loadedDocumento])

  const handleSearch = async () => {
    const documentoSearch = documento

    const isCpf = isCPF(documento)

    const hasSerasaPermission = providers.includes(
      EXCLUSIVE_CONTENTS.PROVIDERS.SERASA
    )
    const hasSpreadPermission = providers.includes(
      EXCLUSIVE_CONTENTS.PROVIDERS.SPREAD
    )
    const outputSerasa = hasSerasaPermission
      ? await askSerasa()
      : { isConfirmed: false, isDismissed: false }
    const outputPartners = !isCpf
      ? await askLookForPartners()
      : { isConfirmed: true, isDismissed: false }

    const outputSpread =
      hasSpreadPermission && !isCpf
        ? await haskSpread({ cnpj: documento })
        : { isConfirmed: false, isDismissed: false, value: 'normal' }

    if (
      outputSerasa.isDismissed ||
      outputPartners.isDismissed ||
      outputSpread.isDismissed
    ) {
      return
    }

    const user = await getCurrentUser()

    const searching = searchDocument({
      documento: documentoSearch,
      spreadType: outputSpread.value,
      enableSerasa: outputSerasa.isConfirmed,
      enablePartners: outputPartners.isConfirmed,
      user,
      options,
      dispatch
    })

    const hasFormPermission = StaticScopeMetadata.getAvailablePages().includes(
      EXCLUSIVE_CONTENTS.PAGES.FORMULARIO
    )

    if (hasFormPermission) {
      const basePath =
        StaticScopeMetadata.getAvailableApps().length > 1 ? '/dossie' : ''
      history.push(`${basePath}/formulario`)
    }

    if (searching && onSearch) {
      onSearch()
    }
  }

  const handleChangeDocument = event => {
    const input = event.target.value
    const cleanedInput = input.replace(/\D/g, '')

    const formatter =
      cleanedInput.length <= 11 ? formatPartialCpf : formatPartialCnpj

    setDocumento(formatter(cleanedInput))
  }

  return (
    <div className='container-fluid mx-0 px-0'>
      <div className='row'>
        <div className='text-primary h6 col-12'>
          Para iniciar uma busca, por favor preencha os campos abaixo
        </div>
      </div>
      <hr className='mt-0' />
      <div className='mt-4 row flex flex-row justify-content-end'>
        {SCOPE === 'boanota' && (
          <div className='form-group col-12 col-md-3 col-lg-3 d-flex flex-column justify-content-center'>
            {isLoading ? (
              <Spinner animation='border' variant='primary' size='sm' />
            ) : isError ? (
              <div>Erro ao carregar créditos</div>
            ) : (
              <div>
                <label
                  htmlFor='documento'
                  className='col-form-label col-form-label-sm flex-grow-1'
                  style={{ fontSize: 12 }}
                >
                  Créditos disponíveis
                </label>
                <OverlayTrigger
                  placement='right'
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderHelpCredit}
                >
                  <span
                    style={{ color: '#509902' }}
                    id='creditos'
                    className='form-control'
                  >
                    <MdAttachMoney color='#509902' size={20} />
                    {formatDecimal(credits)}
                  </span>
                </OverlayTrigger>
              </div>
            )}
          </div>
        )}
        <div className='form-group col-12 col-md-3 col-lg-3 d-flex flex-column'>
          <label
            htmlFor='documento'
            className='col-form-label col-form-label-sm flex-grow-1'
            style={{ fontSize: 12 }}
          >
            Digite o CPF ou CNPJ que deseja pesquisar
          </label>
          <input
            type='text'
            id='documento'
            className='form-control'
            style={{ height: 38 }}
            onChange={handleChangeDocument}
            value={documento}
            onKeyDown={e => {
              if (e.code === 'Enter') {
                handleSearch()
              }
            }}
          />
        </div>
        {SCOPE === 'boanota' && (
          <div className='form-group col-12 col-md-3 col-lg-4 d-flex flex-column'>
            <label
              htmlFor='documento'
              className='col-form-label col-form-label-sm flex-grow-1'
              style={{ fontSize: 12 }}
            >
              <span className='mr-1' style={{ fontSize: 12 }}>
                Selecionar os tipos de dados a serem pesquisados
              </span>
              <OverlayTrigger
                placement='right'
                delay={{ show: 250, hide: 400 }}
                overlay={renderHelpCase}
              >
                <FiHelpCircle />
              </OverlayTrigger>
            </label>
            <Select
              size={100}
              styles={{
                multiValueRemove: (base, state) => {
                  return state.data.fixed ? { ...base, display: 'none' } : base
                },
                option: (styles, { isDisabled, isFocused, isSelected }) => {
                  return {
                    ...styles,
                    backgroundColor: isDisabled
                      ? undefined
                      : isSelected
                      ? '#001e64'
                      : isFocused
                      ? '#0045c8'
                      : undefined
                  }
                },
                multiValueLabel: (styles, state) => ({
                  ...styles,
                  fontSize: '11px',
                  backgroundColor: '#001e64',
                  color: '#fff',
                  ...(state.data.fixed ? { paddingRight: 6 } : {})
                })
              }}
              options={SEARCH_OPTIONS}
              isMulti
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              isClearable={options.some(item => !item.fixed)}
              value={options}
              onChange={(changedItems, { option }) => {
                if (changedItems === null) {
                  setOptions([])
                  return
                }
                const includeAll = changedItems.some(
                  item => item.value === 'all'
                )

                let newOptions =
                  includeAll && option.value !== 'all'
                    ? changedItems.filter(item => item.value !== 'all')
                    : includeAll
                    ? [SEARCH_OPTIONS[0]]
                    : changedItems

                if (
                  newOptions.length > 0 &&
                  !includeAll &&
                  !newOptions.some(item => item.value === 'basicInfo')
                ) {
                  newOptions = [SEARCH_OPTIONS[1], ...newOptions]
                }
                newOptions.sort((a, b) => {
                  if (a.fixed) {
                    return -1
                  }

                  if (b.fixed) {
                    return 1
                  }

                  return 0
                })

                setOptions(newOptions)
              }}
            />
          </div>
        )}
        <div
          className='form-group col-12 col-md-2 col-lg-1 d-flex align-items-end justify-content-end'
          style={{ minWidth: 120 }}
        >
          <button
            type='button'
            className='btn btn-primary text-uppercase'
            onClick={handleSearch}
            style={{ height: 38, minWidth: 111 }}
          >
            Pesquisar
          </button>
        </div>
      </div>
    </div>
  )
}

const askSerasa = async () => {
  let isConfirmed = false
  let isDismissed = true

  const output = await Swal.fire({
    title: 'Você deseja utilizar as informações do Serasa?',
    text:
      'A consulta que utilize as informações do Serasa possuem um custo adicional que é cobrado com base no número de pessoas relacionadas',
    icon: 'warning',
    showDenyButton: true,
    focusDeny: true, // foca por padrão no botão negativo para que não faça a busca no Serasa se a pessoa digitar o número do documento e apertar ENTER
    confirmButtonColor: '#3085d6',
    denyButtonColor: '#d33',
    confirmButtonText: 'Utilizar',
    denyButtonText: 'Não utilizar'
  })

  isConfirmed = output.isConfirmed
  isDismissed = output.isDismissed

  return {
    isConfirmed,
    isDismissed
  }
}

const askLookForPartners = async () => {
  let isConfirmed = false
  let isDismissed = true

  const output = await Swal.fire({
    title: 'Você deseja buscar familiares dos sócios?',
    text:
      'Ao buscar por familiares dos sócios, você poderá encontrar informações como empresas familiares',
    icon: 'warning',
    showDenyButton: true,
    focusDeny: true, // foca por padrão no botão negativo para que não faça a busca por familiares se a pessoa digitar o número do documento e apertar ENTER
    confirmButtonColor: '#3085d6',
    denyButtonColor: '#d33',
    confirmButtonText: 'Buscar',
    denyButtonText: 'Não buscar'
  })

  isConfirmed = output.isConfirmed
  isDismissed = output.isDismissed

  return {
    isConfirmed,
    isDismissed
  }
}

const haskSpread = async ({ cnpj }) => {
  try {
    Swal.fire({
      title: 'Por favor, aguarde',
      icon: 'info',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
    const { hasSpread, hasSpreadConsolidado } = await API.spread.exists({
      cnpj
    })
    Swal.hideLoading()
    Swal.close()

    if (hasSpread && !hasSpreadConsolidado) {
      return {
        isConfirmed: true,
        isDismissed: false,
        value: 'normal'
      }
    }

    if (!hasSpread && hasSpreadConsolidado) {
      return {
        isConfirmed: true,
        isDismissed: false,
        value: 'consolidado'
      }
    }

    if (hasSpread && hasSpreadConsolidado) {
      const { isConfirmed, value } = await Swal.fire({
        title: 'Selecione o tipo de spread',
        text:
          'A empresa possui spread normal e consolidado. Digite "normal" ou "consolidado" para selecionar o tipo de spread que deseja utilizar.',
        input: 'text',
        inputLabel: 'Digite "normal" ou "consolidado" para confirmar',
        confirmButtonText: 'continuar',
        denyButtonText: 'cancelar',
        inputValidator: value => {
          const mappedValue = (value || '').toLowerCase()
          if (!value || !['normal', 'consolidado'].includes(mappedValue)) {
            return 'Você deve digitar "normal" ou "consolidado" para confirmar a ação'
          }
        }
      })

      if (!isConfirmed) {
        return {
          isConfirmed: false,
          isDismissed: true
        }
      }

      return {
        isConfirmed: true,
        isDismissed: false,
        value
      }
    }

    return {
      isConfirmed: false,
      isDismissed: false,
      value: 'normal'
    }
  } catch (err) {
    Swal.hideLoading()
    Swal.close()

    console.log(err)
    await Swal.fire({
      title: 'Erro ao verificar o spread',
      text: 'Não foi possível verificar o spread',
      icon: 'error',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK'
    })

    return {
      isConfirmed: false,
      isDismissed: true
    }
  }
}

const renderHelpCase = props => (
  <Tooltip {...props}>
    Os dados básicos são obrigatórios para todas as buscas, porquê são a base
    para todas as outras informações. Por isso, ela sempre estará habilitada.
  </Tooltip>
)

const renderHelpCredit = props => (
  <Tooltip {...props}>
    Créditos disponíveis para realizar pesquisas. Caso não tenha basta clicar em
    recarregar.
  </Tooltip>
)

export default Search
