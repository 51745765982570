import { useRef, useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import useSWR from 'swr'

import API from '../services/api'

import mapWithRelationship from '../lib/detectRelationship'
import { PROGRESS_TYPES } from '../lib/progressStatus'
import {
  fetchBandeiraAmarelaFromS3,
  fetchDadosBasicosFromS3,
  fetchLogSulFromS3,
  fetchMatchGrupoSocietarioFromS3,
  fetchSulbrasilVisitasFromS3
} from '../lib/s3Theme'

import { selectMinData } from '../redux/selectors/dadosBasicos'
import { selectRelateds } from '../redux/selectors/relateds'

import { usePrevious } from '../utils'

const INITIAL_DADOS_BASICOS_DATA = { data: {}, loaded: false, error: false }
const INITIAL_MATCH_GRUPO_SOCIETARIO_DATA = {
  email: { data: {}, loaded: false, error: false },
  endereco: { data: {}, loaded: false, error: false },
  telefone1: { data: {}, loaded: false, error: false },
  telefone2: { data: {}, loaded: false, error: false }
}
const INITIAL_PEP_DATA = { data: [], loaded: false, error: false }

const LOGSUL_DATA = {
  cedente: {
    atividade: undefined,
    codigo: undefined,
    codigoUsuarioRcs: undefined,
    dataConstituida: undefined,
    dataRenovacao: undefined,
    nome: undefined,
    nomeFantasia: undefined,
    situacao: undefined,
    status: undefined,
    statusCode: undefined
  },
  consultaSarasa: {
    codigoUsuarioConsulta: undefined,
    dataAtualizacao: undefined,
    dataConsulta: undefined,
    dataFundacao: undefined,
    dcrRelatorio: undefined,
    dcrSituacaoCadastro: undefined,
    dcrTipoSociedade: undefined,
    documentoCnpj: undefined,
    funcionarios: undefined,
    nomeFatansia: undefined,
    nomeFilial: undefined,
    qtdFiliais: undefined,
    ramoAtividade: undefined,
    razaoSocial: undefined,
    status: undefined,
    statusCode: undefined,
    xmlCnx: undefined,
    xmlConsulta: undefined
  },
  contratoGeral: {
    details: undefined,
    status: undefined,
    statusCode: undefined
  },
  faturamento: {
    data: [],
    status: undefined,
    statusCode: undefined,
    valor: []
  },
  prospeccaoRevisao: {
    dataAtualizacao: undefined,
    descricaoCerCds: undefined,
    descricaoInfEmpresaClg: undefined,
    descricaoCerCit: undefined,
    descricaoRamo: undefined,
    funcionarios: undefined,
    historico: undefined,
    observacaoSugestoes: undefined,
    relatoRevisao: undefined,
    relatoVisita: undefined,
    status: undefined,
    statusCode: undefined,
    sugestoesComerciais: undefined,
    valorFolha: undefined,
    valorLimite: undefined,
    valorLimiteSacados: undefined,
    valorMensalTitulosDescontados: undefined
  },
  visita: {
    codigoUsuarioCadastro: undefined,
    codigoUsuarioVisita: undefined,
    dataAtualizacao: undefined,
    dataVisita: undefined,
    descricaoRamo: undefined,
    faturamento: undefined,
    funcionarios: undefined,
    status: undefined,
    statusCode: undefined,
    valorFolha: undefined,
    valorMensalTitulosDescontados: undefined,
    valorVendaAPrazo: undefined
  }
}

const INITIAL_LOGSUL_DATA = {
  data: LOGSUL_DATA,
  loaded: false,
  error: false
}

export const useDadosBasicos = () => {
  const { targetDocument } = useSelector(selectMinData)
  const prevDocument = usePrevious(targetDocument)
  const dadosBasicosTheme = useSelector(state => state.dadosBasicosTheme)

  const [status, setStatus] = useState(PROGRESS_TYPES.PENDING)

  const [data, setData] = useState(INITIAL_DADOS_BASICOS_DATA)

  useEffect(() => {
    if (prevDocument === targetDocument) {
      return
    }

    if (prevDocument) {
      setStatus(PROGRESS_TYPES.PENDING)
      setData(INITIAL_DADOS_BASICOS_DATA)
    }
  }, [targetDocument, prevDocument])

  useEffect(() => {
    if (!dadosBasicosTheme.loaded) {
      return
    }

    fetchDadosBasicosFromS3({
      payload: dadosBasicosTheme
    })
      .then(dadosBasicos => {
        setData(dadosBasicos)
        setStatus(PROGRESS_TYPES.LOADED)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({ data: {}, loaded: false, error: true })
        setStatus(PROGRESS_TYPES.ERROR)
      })
  }, [dadosBasicosTheme])

  return { data: data?.data, status }
}

export const usePep = () => {
  const relateds = useSelector(selectRelateds)
  const { targetDocument } = useSelector(selectMinData)
  const prevDocument = usePrevious(targetDocument)
  const [status, setStatus] = useState(PROGRESS_TYPES.PENDING)

  const [data, setData] = useState(INITIAL_PEP_DATA)

  const bandeiraAmarelaTheme = useSelector(state => state.bandeiraAmarelaTheme)

  useEffect(() => {
    if (prevDocument === targetDocument) {
      return
    }

    if (prevDocument) {
      setStatus(PROGRESS_TYPES.PENDING)
      setData(INITIAL_PEP_DATA)
    }
  }, [targetDocument, prevDocument])

  useEffect(() => {
    if (!bandeiraAmarelaTheme.pep.loaded) {
      return
    }

    fetchBandeiraAmarelaFromS3({
      pepPayload: bandeiraAmarelaTheme.pep
    })
      .then(bandeiraAmarela => {
        setData(bandeiraAmarela.pep)
        setStatus(PROGRESS_TYPES.LOADED)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          data: [],
          loaded: false,
          error: true
        })
        setStatus(PROGRESS_TYPES.ERROR)
      })
  }, [bandeiraAmarelaTheme])

  return {
    data: mapWithRelationship({
      array: data?.data || [],
      documentKey: 'document',
      nameKey: 'name',
      relateds
    }).map(item => ({ ...item, isPep: item.data.length > 0 })),
    status
  }
}

export const useLogSul = () => {
  const { targetDocument } = useSelector(selectMinData)
  const prevDocument = usePrevious(targetDocument)
  const [status, setStatus] = useState(PROGRESS_TYPES.PENDING)

  const [data, setData] = useState(INITIAL_LOGSUL_DATA)

  const logsulTheme = useSelector(state => state.logsulTheme)

  useEffect(() => {
    if (prevDocument === targetDocument) {
      return
    }

    if (prevDocument) {
      setStatus(PROGRESS_TYPES.PENDING)
      setData(INITIAL_LOGSUL_DATA)
    }
  }, [targetDocument, prevDocument])

  useEffect(() => {
    if (!logsulTheme.loaded) {
      return
    }

    fetchLogSulFromS3({
      payload: logsulTheme
    })
      .then(({ logsul }) => {
        setData(logsul)
        setStatus(PROGRESS_TYPES.LOADED)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          data: LOGSUL_DATA,
          loaded: false,
          error: true
        })
        setStatus(PROGRESS_TYPES.ERROR)
      })
  }, [logsulTheme])

  return {
    data: data?.data || [],
    status
  }
}

export const useSulbarsilVisitas = () => {
  const { targetDocument } = useSelector(selectMinData)
  const prevDocument = usePrevious(targetDocument)
  const [status, setStatus] = useState(PROGRESS_TYPES.PENDING)
  const [data, setData] = useState(INITIAL_LOGSUL_DATA)
  const visitasTheme = useSelector(state => state.visitasTheme)

  useEffect(() => {
    if (prevDocument === targetDocument) {
      return
    }

    if (prevDocument) {
      setStatus(PROGRESS_TYPES.PENDING)
      setData(INITIAL_LOGSUL_DATA)
    }
  }, [targetDocument, prevDocument])

  useEffect(() => {
    if (!visitasTheme.loaded) {
      return
    }

    fetchSulbrasilVisitasFromS3({
      payload: visitasTheme
    })
      .then(({ visitas }) => {
        setData(visitas)
        setStatus(PROGRESS_TYPES.LOADED)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          data: LOGSUL_DATA,
          loaded: false,
          error: true
        })
        setStatus(PROGRESS_TYPES.ERROR)
      })
  }, [visitasTheme])

  return {
    data: data?.data || [],
    status
  }
}

export const useMatchGrupoSocietario = (
  keys = ['email', 'endereco', 'telefone1', 'telefone2']
) => {
  const { targetDocument } = useSelector(selectMinData)
  const prevDocument = usePrevious(targetDocument)
  const matchGrupoSocietarioTheme = useSelector(
    state => state.matchGrupoSocietarioTheme
  )
  const ref = useRef()

  // Store current value in ref
  useEffect(() => {
    ref.current = keys
  }, [keys])

  const [status, setStatus] = useState(PROGRESS_TYPES.PENDING)

  const [data, setData] = useState(INITIAL_MATCH_GRUPO_SOCIETARIO_DATA)

  useEffect(() => {
    if (prevDocument === targetDocument) {
      return
    }

    if (prevDocument) {
      setStatus(PROGRESS_TYPES.PENDING)
      setData(INITIAL_MATCH_GRUPO_SOCIETARIO_DATA)
    }
  }, [targetDocument, prevDocument])

  useEffect(() => {
    if (
      !matchGrupoSocietarioTheme.email.loaded &&
      !matchGrupoSocietarioTheme.endereco.loaded &&
      !matchGrupoSocietarioTheme.telefone1.loaded &&
      !matchGrupoSocietarioTheme.telefone2.loaded
    ) {
      return
    }

    const internalKeys = ref.current

    const payload = [
      internalKeys.includes('email') && {
        emailPayload: matchGrupoSocietarioTheme.email
      },
      internalKeys.includes('endereco') && {
        enderecoPayload: matchGrupoSocietarioTheme.endereco
      },
      internalKeys.includes('telefone1') && {
        telefone2Payload: matchGrupoSocietarioTheme.telefone1
      },
      internalKeys.includes('telefone2') && {
        telefone1Payload: matchGrupoSocietarioTheme.telefone2
      }
    ]
      .filter(Boolean)
      .reduce((acc, curr) => ({ ...acc, ...curr }), {})

    fetchMatchGrupoSocietarioFromS3(payload)
      .then(match => {
        setData(match)
        setStatus(PROGRESS_TYPES.LOADED)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          email: { data: [], loaded: false, error: true },
          endereco: { data: [], loaded: false, error: true },
          telefone1: { data: [], loaded: false, error: true },
          telefone2: { data: [], loaded: false, error: true }
        })
        setStatus(PROGRESS_TYPES.ERROR)
      })
  }, [matchGrupoSocietarioTheme, ref])

  return {
    data: Object.fromEntries(
      Object.entries(data).filter(([key, _]) => keys.includes(key))
    ),
    status
  }
}

export const useCredits = shouldRun => {
  const { data, error, isLoading, mutate } = useSWR(
    shouldRun ? 'credits' : null,
    API.funds.get
  )

  return {
    credits: data,
    isLoading,
    isError: error,
    forceRefresh: useCallback(() => mutate('credits'), [mutate])
  }
}
