import { createSelector } from 'reselect'

const getThemes = state => state.globalStep.themes
const getTargetDocument = state => state.globalStep.targetDocument

export const selectSearchOptions = createSelector(
  getThemes,
  getTargetDocument,
  (themes, targetDocument) => {
    const dadosBasicos = targetDocument
    const grupoSocietario = themes.includes('grupoSocietario')
    const patrimonio = themes.includes('patrimonio')
    const pontosDeAtencao = themes.includes('pontosDeAtencao')
    const offshore = themes.includes('offshore')
    const endividamento = themes.includes('endividamento')
    const juridico = themes.includes('juridico')
    const midiaDesabonadora = themes.includes('midiaDesabonadora')
    const operacionais = themes.includes('operacionais')

    return {
      pages: {
        dadosBasicos,
        grupoSocietario,
        patrimonio,
        pontosDeAtencao,
        offshore,
        endividamento,
        juridico,
        midiaDesabonadora,
        operacionais
      }
    }
  }
)
