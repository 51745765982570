import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { Button, Modal, Spinner } from 'react-bootstrap'
import * as yup from 'yup'

import { SCOPE } from '../../config/env'

import API from '../../services/api'

import { useCredits } from '../../utils/hooks'
import { validateForm } from '../../utils/form'

const SCHEMA = yup.object().shape({
  credits: yup
    .number()
    .required('Campo obrigatório')
    .min(1, 'Valor mínimo é 1')
})

export default function ModalCredits ({ show, onClose }) {
  const [state, setState] = useState({
    form: {},
    status: 'normal',
    error: null,
    validation: {}
  })
  const { forceRefresh } = useCredits(SCOPE === 'boanota')

  if (!show) {
    return null
  }

  const handleClose = () => {
    setState({ form: {}, status: 'normal', error: null, validation: {} })
    onClose()
  }

  const handleSubmit = async () => {
    try {
      const isValidPld = await validateForm({
        schema: SCHEMA,
        data: state.form
      })
        .then(() => true)
        .catch(err => {
          setState(prev => ({ ...prev, validation: err.errorObject || {} }))
          return false
        })

      if (!isValidPld) {
        return
      }

      setState(prev => ({ ...prev, status: 'loading' }))
      await API.funds.update({ credits: state.form.credits })
      setState(prev => ({ ...prev, status: 'normal' }))
      forceRefresh()
      Swal.fire({
        icon: 'success',
        title: 'Pedido enviado com sucesso',
        showConfirmButton: false,
        timer: 1500
      })
      handleClose()
    } catch (err) {
      console.error(err)
      const message =
        err.response?.data?.message || err.message || 'Erro ao enviar pedido'
      setState(prev => ({ ...prev, status: 'error', error: message }))
    }
  }

  return (
    <Modal show size='lg' onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>ADICIONE CRÉDITOS</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {state.status === 'error' ? (
          <p>{state.error}</p>
        ) : state.status === 'loading' ? (
          <Spinner animation='border' variant='primary' />
        ) : (
          <>
            <div className='form-group'>
              <label htmlFor='credits'>Créditos</label>
              <input
                type='number'
                id='credits'
                name='credits'
                value={state.form.credits || ''}
                onChange={e =>
                  setState(prev => ({
                    ...prev,
                    form: {
                      ...prev.form,
                      credits: Number(Number(e.target.value).toFixed(0))
                    }
                  }))
                }
                className={`form-control ${
                  state.validation.credits ? 'is-invalid' : ''
                }`}
                min={1}
                step={1}
                max
              />
              <div className='invalid-feedback'>{state.validation.credits}</div>
            </div>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant='primary'
          onClick={handleSubmit}
          disabled={state.status !== 'normal'}
        >
          ENVIAR PEDIDO
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
