import { toast } from 'react-toastify'
import { batch } from 'react-redux'

import { isValidCNPJ, isValidCPF } from './validators'

import { resetOffshoreTheme } from '../redux/store/offshoreTheme'
import { resetJuridicoTheme } from '../redux/store/juridicoTheme'
import { resetPatrimonioTheme } from '../redux/store/patrimonioTheme'
import { resetDadosBasicosTheme } from '../redux/store/dadosBasicosTheme'
import { resetEndividamentoTheme } from '../redux/store/endividamentoTheme'
import { resetOperacionaisTheme } from '../redux/store/operacionaisTheme'

import { resetGrafoData } from '../redux/actions/grafo'
import { resetGoogleMaps } from '../redux/actions/googleMaps'
import { resetMatches } from '../redux/actions/matches'
import { resetDocx } from '../redux/store/doc'
import {
  openProgressStatus,
  resetProgressStatus
} from '../redux/store/progressStatus'
import { resetGlobalStep, trocarDocumento } from '../redux/store/globalStep'
import { resetBandeiraAmarelaTheme } from '../redux/store/bandeiraAmarelaTheme'
import { resetRelateds } from '../redux/store/relateds'
import { resetSpreadTheme } from '../redux/store/spreadTheme'
import { resetVisitasTheme } from '../redux/store/visitasTheme'
import { resetSulBrasil } from '../redux/store/sulbrasil'
import { resetDiscreditingMediaTheme } from '../redux/store/discreditingMediaTheme'

import { SEARCH_OPTIONS } from '../config/consts'

const TOAST_ID_DOCUMENT_INPUT = 'error_invalid_document_input'

export default function searchDocument ({
  documento,
  enableSerasa,
  enablePartners,
  spreadType,
  options,
  user,
  dispatch
}) {
  documento = documento.replace(/\D/g, '')
  if (documento.length !== 11 && documento.length !== 14) {
    toast.error('Documento inválido! Deve informar um CPF ou CNPJ', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: TOAST_ID_DOCUMENT_INPUT
    })
    return false
  }

  if (documento.length === 11 && !isValidCPF(documento)) {
    toast.error('CPF inválido', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: TOAST_ID_DOCUMENT_INPUT
    })
    return false
  }

  if (documento.length === 14 && !isValidCNPJ(documento)) {
    toast.error('CNPJ inválido', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: TOAST_ID_DOCUMENT_INPUT
    })
    return false
  }

  if (documento === '') {
    toast.error('Sem documento', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: TOAST_ID_DOCUMENT_INPUT
    })
    return false
  }

  const allKeys = SEARCH_OPTIONS.filter(item => item.value !== 'all')
    .flatMap(item => item.flags)
    .reduce((obj, flag) => {
      obj[flag] = true // desabilita todas as flags por default
      return obj
    }, {})

  const allThemes = SEARCH_OPTIONS.filter(item => item.value !== 'all').flatMap(
    item => item.value
  )

  const internalOptions = options.find(item => item.value === 'all')
    ? Object.fromEntries(
        Object.entries(allKeys).map(([key, _]) => [key, false])
      )
    : options
        .flatMap(item => item.flags)
        .reduce((obj, flag) => {
          obj[flag] = false // ativa apenas as flags selecionadas
          return obj
        }, {})

  const themes = options.find(item => item.value === 'all')
    ? allThemes
    : options.flatMap(item => item.value)

  if (options.length === 0) {
    toast.error('Selecione ao menos uma opção de busca', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: TOAST_ID_DOCUMENT_INPUT
    })
    return
  }

  batch(() => {
    dispatch(resetSulBrasil())

    dispatch(resetGoogleMaps())
    dispatch(resetGrafoData())
    dispatch(resetMatches())
    dispatch(resetDocx())

    dispatch(resetBandeiraAmarelaTheme())
    dispatch(resetDadosBasicosTheme())
    dispatch(resetOperacionaisTheme())
    dispatch(resetEndividamentoTheme())
    dispatch(resetJuridicoTheme())
    dispatch(resetOffshoreTheme())
    dispatch(resetPatrimonioTheme())
    dispatch(resetSpreadTheme())
    dispatch(resetVisitasTheme())
    dispatch(resetDiscreditingMediaTheme())

    dispatch(resetRelateds())
    dispatch(resetProgressStatus())
    dispatch(resetGlobalStep())
  })

  dispatch(
    trocarDocumento({
      documento,
      enableSerasa,
      enablePartners,
      spreadType,
      themes,
      options: { ...allKeys, ...internalOptions }
    })
  )

  dispatch(openProgressStatus())
  return true
}
