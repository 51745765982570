import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { toast } from 'react-toastify'

import {
  Content,
  FiltrosComponent,
  DetailsJuridico,
  CustomWindow,
  RemoveSelected,
  Loading
} from '../../../../components'
import Select from './components/Select'
import Menu from './components/Menu'
import Processos from './components/Processos'

import { selectMinData } from '../../../../redux/selectors/dadosBasicos'
import { selectSearchOptions } from '../../../../redux/selectors/searchOptions'

import '../../../../assets/sass/juridico.scss'

import { StaticS3Client } from '../../../../lib/s3'
import { PROGRESS_TYPES } from '../../../../lib/progressStatus'

import { getIdToken, toCamelCase } from '../../../../utils'

export default function Juridico () {
  const relateds = useSelector(state => state.juridicoTheme.relateds)
  const { pages: enabledPages } = useSelector(selectSearchOptions)
  const { targetName, targetDocument, isCPF } = useSelector(selectMinData)

  const [mounted, setMounted] = useState(false)

  const [consultaTipo, setTipoConsulta] = useState(0)
  const [processoDetails, setProcessoDetails] = useState(null)
  const [processoChoiced, setProcessoChoiced] = useState(null)
  const [isSearching, setIsSearching] = useState(true)
  const [resultData, setResultData] = useState(undefined)
  const [filteredResultData, setFilteredResultData] = useState(undefined)
  const [targetData, setTargetData] = useState(undefined)
  const [selectedContent, setSelectedContent] = useState()

  const handleSearch = useCallback(
    async param => {
      setProcessoChoiced(null)
      setProcessoDetails(null)

      if (consultaTipo === 0 && targetData) {
        setResultData(targetData)
        return
      }

      const content = param || selectedContent
      if (!content) {
        toast.error('Selecione um documento para consultar')
        return
      }

      setIsSearching(true)
      try {
        const accessToken = await getIdToken()
        const s3Client = await StaticS3Client.getInstance({ accessToken })

        const data = await s3Client.readFile({
          bucket: content.bucketName,
          key: content.key
        })
        setResultData(toCamelCase(data))
        if (consultaTipo === 0) {
          setTargetData(toCamelCase(data))
        }
      } catch (err) {
        console.error(err)
        setSelectedContent(null)
      } finally {
        setIsSearching(false)
      }
    },
    [consultaTipo, targetData, selectedContent]
  )

  useEffect(() => {
    if (mounted || !relateds || targetData) {
      return
    }

    const type = isCPF ? 'pf' : 'pj'

    const targetQueryType = 'document'
    const targetMetadata = relateds.find(
      item =>
        item.document === targetDocument &&
        type === item.type &&
        item.queryType === targetQueryType
    )
    const isReady = targetMetadata?.status === PROGRESS_TYPES.LOADED

    if (!isReady) {
      return
    }

    const execute = async () => {
      const accessToken = await getIdToken()
      const s3Client = await StaticS3Client.getInstance({ accessToken })

      await s3Client
        .readFile({
          bucket: targetMetadata.bucketName,
          key: targetMetadata.key
        })
        .then(data => {
          const formatted = toCamelCase(data)
          setTargetData(formatted)
          setResultData(formatted)
          setSelectedContent(targetMetadata)
        })
    }

    setIsSearching(true)
    execute().finally(() => {
      setIsSearching(false)
      setMounted(true)
    })
  }, [mounted, relateds, targetDocument, targetData, isCPF])

  const clickItemProcesso = processo => {
    setProcessoDetails(null)
    setProcessoChoiced(null)

    const toastId = 'carregando-detalhes-protesto'
    toast('Carregando dados deste processo', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId,
      autoClose: false
    })

    setProcessoDetails([processo])
    setProcessoChoiced(processo)
    toast.dismiss(toastId)
  }

  const renderSelectedContent = () => {
    if (isSearching) {
      return <Loading />
    }

    if (!selectedContent || !resultData) {
      return <div />
    }

    const content = filteredResultData || resultData

    const dbjusArray = [
      ...(content.bigboost?.found ?? []),
      ...(content.bigboost?.notFound?.probable ?? [])
    ]
    const { resumo } = resultData

    return (
      <>
        <ResultadosDaBusca length={dbjusArray.length} />
        <Resumo text={resumo} />
        <Processos
          data={dbjusArray}
          onSelectProcesso={clickItemProcesso}
          scores={content?.scores}
          probableVinculados={
            content?.bigboost?.notFound?.probableVinculados || []
          }
          vinculadosMap={content?.bigboost?.notFound?.vinculadosMap || {}}
        />
        <DetailsJuridico
          processoDetails={processoDetails}
          processo={processoChoiced}
          resultData={resultData}
          onCleanSelected={() => {
            setProcessoChoiced(null)
            setProcessoDetails(null)
          }}
        />

        {processoDetails && (
          <RemoveSelected
            onCleanSelected={() => {
              setProcessoChoiced(null)
              setProcessoDetails(null)
            }}
          />
        )}
      </>
    )
  }

  const renderSelect = () => {
    if (consultaTipo === 0) {
      const targetType = isCPF ? 'pf' : 'pj'
      const targetQueryType = 'document'
      return (
        <div className='col-12 d-flex'>
          <button
            type='button'
            className='btn btn-outline-dark font-weight-bold text-uppercase'
            onClick={() => {
              const targetMetadata = relateds.find(
                item =>
                  item.document === targetDocument &&
                  targetType === item.type &&
                  item.queryType === targetQueryType
              )
              setSelectedContent(targetMetadata)
              handleSearch(targetMetadata)
            }}
          >
            <span>Re-pesquisar</span>
          </button>
        </div>
      )
    }
    if (consultaTipo === 1) {
      return (
        <>
          <div className='col-10'>
            <Select
              options={relateds}
              type='pf'
              value={selectedContent}
              onChange={setSelectedContent}
            />
          </div>
          <div className='col-2 d-flex justify-content-end align-items-end'>
            <div className='h-100'>
              <button
                type='button'
                className='h-100 btn btn-outline-dark font-weight-bold text-uppercase'
                onClick={() => handleSearch()}
              >
                <span>Pesquisar</span>
              </button>
            </div>
          </div>
        </>
      )
    }

    if (consultaTipo === 2) {
      return (
        <>
          <div className='col-10'>
            <Select
              options={relateds}
              type='pj'
              value={selectedContent}
              onChange={setSelectedContent}
            />
          </div>
          <div className='col-2 d-flex justify-content-end align-items-end'>
            <div className='h-100'>
              <button
                type='button'
                className='h-100 btn btn-outline-dark font-weight-bold text-uppercase'
                onClick={() => handleSearch()}
              >
                <span>Pesquisar</span>
              </button>
            </div>
          </div>
        </>
      )
    }
  }

  return (
    <>
      <ReactTooltip />
      <Content
        title={`Jurídico ${targetName ? `- ${targetName}` : ''}`}
        subtitle='Busca judicial cruzada com diversas fontes.'
      >
        {!targetDocument ? null : !enabledPages.juridico ? (
          <div className='d-flex w-100 h-100 flex-1 justify-content-center align-items-center'>
            <p className='h3'>Esta aba está desabilitada!</p>
          </div>
        ) : (
          <>
            <CustomWindow title='Filtros'>
              <Menu
                selected={consultaTipo}
                onChange={newTipo => {
                  setTipoConsulta(newTipo)
                }}
              />
              <FiltrosComponent
                data={resultData}
                onChangeFilter={newResultData =>
                  setFilteredResultData(newResultData)
                }
              />
              {targetDocument && (
                <div className='row mt-3 mb-2'>{renderSelect()}</div>
              )}
            </CustomWindow>

            {targetDocument && renderSelectedContent()}
          </>
        )}
      </Content>
    </>
  )
}

const ResultadosDaBusca = ({ length }) => {
  return (
    <CustomWindow title='Resultados da Busca' defaultOpen>
      <div style={{ fontWeight: '300' }}>
        Foram encontrados <span style={{ fontWeight: '600' }}>{length}</span>{' '}
        processos
      </div>
    </CustomWindow>
  )
}

const Resumo = ({ text }) => {
  return (
    <CustomWindow title='Resumo' defaultOpen>
      <div>{text}</div>
    </CustomWindow>
  )
}
