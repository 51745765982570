import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { Content, Loading } from '../../../../components'

import { selectMinData } from '../../../../redux/selectors/dadosBasicos'
import { selectSearchOptions } from '../../../../redux/selectors/searchOptions'

import {
  fetchOperacionaisFromS3,
  fetchSpreadFromS3
} from '../../../../lib/s3Theme'
import {
  Caixa,
  TabelaMatchFuncionarios as TabelaMatchFuncionariosComponent
} from './components'
import { EXCLUSIVE_CONTENTS, StaticScopeMetadata } from '../../../../lib/scope'
import { isPending } from '../../../../utils'
import { SCOPE } from '../../../../config/env'

export default function Operacionais () {
  const spreadTheme = useSelector(state => state.spreadTheme)
  const operacionaisTheme = useSelector(state => state.operacionaisTheme)
  const { pages: enabledPages } = useSelector(selectSearchOptions)

  const [loadingSpread, setLoadingSpread] = useState(false)
  const [loadedOperacionais, setLoadedOperacionais] = useState(false)
  const [loadingOperacionais, setLoadingOperacionais] = useState(false)
  const [data, setData] = useState({
    endividamento: { data: {}, loaded: false, error: false },
    informacoesEconomicoFinanceiras: {
      data: {},
      loaded: false,
      error: false
    },
    funcionarios: { data: [], loaded: false, error: false },
    exFuncionarios: { data: [], loaded: false, error: false },
    matchFuncionarios: { data: [], loaded: false, error: false }
  })

  const { targetName, targetDocument } = useSelector(selectMinData)

  useEffect(() => {
    if (!spreadTheme.s3Path || SCOPE !== 'sulbrasil') {
      return
    }

    setLoadingSpread(true)
    Promise.all([fetchSpreadFromS3({ payload: spreadTheme })])
      .then(([spread]) => {
        setData(prev => ({
          ...prev,
          ...spread
        }))
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData(prev => ({
          ...prev,
          endividamento: { data: {}, loaded: false, error: true },
          informacoesEconomicoFinanceiras: {
            data: {},
            loaded: false,
            error: true
          }
        }))
      })
      .finally(() => {
        setLoadingSpread(false)
      })
  }, [spreadTheme])

  useEffect(() => {
    if (!operacionaisTheme.s3Path) {
      return
    }

    setLoadingOperacionais(true)

    fetchOperacionaisFromS3({ payload: operacionaisTheme })
      .then(operacionais => setData(prev => ({ ...prev, ...operacionais })))
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData(prev => ({
          ...prev,
          funcionarios: { data: [], loaded: false, error: true },
          exFuncionarios: { data: [], loaded: false, error: true },
          matchFuncionarios: { data: [], loaded: false, error: true }
        }))
      })
      .finally(() => {
        setLoadingOperacionais(false)
        setLoadedOperacionais(true)
      })
  }, [operacionaisTheme])

  const isStepPending =
    targetDocument && isPending(spreadTheme) && isPending(operacionaisTheme)

  return (
    <Content
      title={`Operacionais ${targetName ? `- ${targetName}` : ''}`}
      subtitle=''
    >
      {!targetDocument ? null : !enabledPages.operacionais ? (
        <div className='d-flex w-100 h-100 flex-1 justify-content-center align-items-center'>
          <p className='h3'>Esta aba está desabilitada!</p>
        </div>
      ) : loadingSpread || loadingOperacionais || isStepPending ? (
        <Loading />
      ) : (
        <>
          <TabelaMatchFuncionarios
            funcionarios={data.funcionarios}
            exFuncionarios={data.exFuncionarios}
            match={data.matchFuncionarios}
            loaded={loadedOperacionais}
          />
          {StaticScopeMetadata.getAvailableTables().includes(
            EXCLUSIVE_CONTENTS.TABLES.CAIXA
          ) && (
            <Caixa
              data={data.informacoesEconomicoFinanceiras.data}
              error={data.informacoesEconomicoFinanceiras.error}
              loaded={data.informacoesEconomicoFinanceiras.loaded}
            />
          )}
        </>
      )}
    </Content>
  )
}

const TabelaMatchFuncionarios = React.memo(
  ({ funcionarios, exFuncionarios, match, loaded }) => {
    return (
      <TabelaMatchFuncionariosComponent
        funcionarios={funcionarios}
        exFuncionarios={exFuncionarios}
        match={match}
        loaded={loaded}
      />
    )
  }
)
