import React, { useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { SCOPE } from '../../config/env'

import Search from '../Search'
import ModalCredits from './ModalCredits'

import * as styles from './style.module.scss'

export default function SubHeader ({ hideSearch, hideTitle }) {
  const location = useLocation()
  const history = useHistory()
  const [state, setState] = useState({ showCredits: false })

  const cleanedPath = location.pathname.replace('/', '')

  return (
    <>
      {cleanedPath !== '' && <Nav />}
      {!hideTitle && (
        <div
          className={`pl-3 mr-3 d-flex flex-row align-items-center ${styles.container}`}
        >
          <div className={`flex-grow-1 ${styles.header}`}>Dossiê 2.0</div>
          {SCOPE === 'boanota' ? (
            <div>
              <button
                type='button'
                className='btn btn-primary mr-2 text-uppercase'
                style={{ minWidth: 111 }}
                onClick={() =>
                  setState(prev => ({
                    ...prev,
                    showCredits: !prev.showCredits
                  }))
                }
              >
                Recarregar
              </button>
            </div>
          ) : (
            <div>
              <button
                type='button'
                className='btn btn-primary mr-2 text-uppercase'
                onClick={() => history.push('/historico')}
              >
                Histórico
              </button>
            </div>
          )}
        </div>
      )}
      {!hideSearch && (
        <div className={`pl-3 mr-4 ${styles.container}`}>
          <Search
            {...(cleanedPath === 'pesquisa'
              ? { onSearch: () => history.push('/dados-basicos') }
              : {})}
          />
        </div>
      )}
      <ModalCredits
        show={state.showCredits}
        onClose={() =>
          setState(prev => ({ ...prev, showCredits: !prev.showCredits }))
        }
      />
    </>
  )
}

const Nav = () => {
  const location = useLocation()

  const cleanedPath = location.pathname.replace('/', '')
  const currentPage = !['login', 'historico'].includes(cleanedPath)
    ? 'Pesquisa de Documentos'
    : 'Histórico Pesquisas'

  return (
    <div className={`d-flex flex-row align-items-center ${styles.container}`}>
      <nav aria-label='breadcrumb'>
        <ol
          className='breadcrumb mb-0'
          style={{ backgroundColor: 'transparent' }}
        >
          {SCOPE !== 'boanota' && (
            <li className='breadcrumb-item'>
              <Link to='/'>Dashboard</Link>
            </li>
          )}
          <li className='breadcrumb-item active' aria-current='page'>
            {currentPage}
          </li>
        </ol>
      </nav>
    </div>
  )
}
